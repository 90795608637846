var rex_php_tags = new RegExp("<\\?php(?:[\\n\\r]|.)+?\\?>(?!\"|'')", "gim");
var rex_script_tags = new RegExp("<script(?:[\\n\\r]|.)+?\\/script>", "gim");
var rex_comments_tags = new RegExp("<!--(?:[\\n\\r]|.)+?-->", "gim");

// // Strips php contents /!\ THIS DELETES PHP TAGS AND EVERYTHING IN BETWEEN /!\
function stripPhpContents(str) {
    return str.replace(rex_php_tags, "");
}

// // Strips js contents /!\ THIS DELETES SCRIPT TAGS AND EVERYTHING IN BETWEEN /!\
function stripJSContents(str) {
    return str.replace(rex_script_tags, "");
}

// // Strips html comments /!\ THIS DELETES COMMENTS TAGS AND EVERYTHING IN BETWEEN /!\
function stripHTMLComments(str) {
    return str.replace(rex_comments_tags, "");
}

function stripCombo(str) {
    var output = stripHTMLComments(str);

    output = stripJSContents(output);
    output = stripPhpContents(output);

    return output;
}

function hideLoader() {
    if (loader) {
        loader.css("display", "none");
        loader
            .removeAttr("style")
            .parent()
            .removeClass("loading");
    }
    if (elmt) {
        elmt.css("display", prev_display);
    }
}

// // Checks any form
function ajax_checkAllForm(
    tabError,
    blockMenuTopConnexionInfobulle,
    path_relative_root
) {
    if (checkAllForm(tabError, blockMenuTopConnexionInfobulle)) {
        if (tabError[0][1] && tabError[0][1] == "clientMailAuthTop") {
            $.ajax({
                url: path_relative_root + "ajax_login_allowed.php",
                type: "get",
                data: {
                    mail: document.getElementById(tabError[0][1]).value,
                    mdp: document.getElementById(tabError[1][1]).value,
                    is_top: true,
                },
                success: function(res) {
                    var url, index_deb, index_fin, provenance;

                    if (res[0] !== "0") {
                        url = window.location.pathname;
                        index_deb = url.lastIndexOf("/");
                        index_fin = url.lastIndexOf(".");
                        provenance = url.substring(index_deb + 1, index_fin);

                        if (provenance === "connexion") {
                            window.location.replace(
                                path_relative_root + create_link("client_index")
                            );
                        } else if (provenance === "identification") {
                            window.location.replace(
                                path_relative_root + "livraison-kiala.php"
                            );
                        } else {
                            window.location.reload();
                        }
                    } else {
                        document.getElementById("AuthTopErr").style.display =
                            "block";

                        $(".loader").css("display", "none");
                    }
                },
            });
        } else if (tabError[0][1] && tabError[0][1] == "mailLostTop") {
            var mail = document.getElementById(tabError[0][1]).value;

            $.ajax({
                url: path_relative_root + "ajax_email_exist.php",
                type: "get",
                data: {mail: mail},
                success: function(res) {
                    if (res == 1) {
                        $.ajax({
                            url:
                                path_relative_root +
                                "ajax_send_new_password_link.php",
                            type: "get",
                            data: {mail: mail},
                            succes: function() {
                                show_lightbox(
                                    "popup_confirm_reinitialisation_mdp"
                                );

                                document.getElementById(
                                    "lostpwdTopErr"
                                ).style.display =
                                    "none";
                                document.getElementById(
                                    "lostpwdTopSuccesTitle"
                                ).style.visibility =
                                    "hidden";

                                hideLoader();
                            },
                        });
                    } else {
                        document.getElementById("lostpwdTopErr").style.display =
                            "block";

                        hideLoader();
                    }
                },
            });
        } else if (tabError[0][1] && tabError[0][1] == "clientMailAuth") {
            $.ajax({
                url: path_relative_root + "ajax_login_allowed.php",
                type: "get",
                data: {
                    mail: document.getElementById(tabError[0][1]).value,
                    mdp: document.getElementById(tabError[1][1]).value,
                    is_top: false,
                },
                success: function(res) {
                    hideLoader();

                    if (res == 1) {
                        $("#auth").submit();

                        return true;
                    } else {
                        document.getElementById("AuthErrErr").style.display =
                            "block";

                        return false;
                    }
                },
            });
        } else if (tabError[0][1] && tabError[0][1] == "mailLost") {
            var mail = document.getElementById(tabError[0][1]).value;

            $.ajax({
                url: path_relative_root + "ajax_email_exist.php",
                type: "get",
                data: {mail: mail},
                success: function(res) {
                    if (res == 1) {
                        $.ajax({
                            url:
                                path_relative_root +
                                "ajax_send_new_password_link.php",
                            type: "get",
                            data: {mail: mail},
                            success: function(res) {
                                show_lightbox(
                                    "popup_confirm_reinitialisation_mdp"
                                );

                                document.getElementById(
                                    "lostpwdErr"
                                ).style.display =
                                    "none";
                                document.getElementById(
                                    "lostpwdSuccesTitle"
                                ).style.visibility =
                                    "hidden";

                                hideLoader();

                                return false;
                            },
                        });
                    } else {
                        document.getElementById("lostpwdErr").style.display =
                            "block";

                        hideLoader();

                        return false;
                    }
                },
            });
        }
    } else {
        $(".loader").css("display", "none");
    }
}

// Reload product from ref
function loadProduct(new_id, type, page, itm_rr_id, rand = '') {
    var form = this;
    var old_id = this.getValue('produit_id');
    var col_id = this.getValue(this.getValue('idCouleur'));
    var sze_id = this.getValue(this.getValue('idTaille'));
    var act = this.getValue('act');
    var qte_itm = 1;

    if (this.elements.namedItem('qteProd') ) {
        var qte_itm = this.elements.namedItem('qteProd').value;
    }

    var is_model = this.getValue('hdn_is_model');
    var is_wishlist = $('#wishlist').length > 0 && page !== 'assoc';
    var is_achat_express = $('#is_achat_express_v2').val() == 1;
    var isCategSuper = this.getValue('isCategSuper') ? this.getValue('isCategSuper') : 0;
    var is_lookbook = $('.rayon_lookbook').length > 0;

    var cpt_prod = itm_rr_id !== '' ? itm_rr_id.replace('_', '') : '';
    var data = {
        produit_id : old_id,
        produit_principal : old_id,
        couleurProd : col_id,
        tailleProd : sze_id,
        act : act,
        qteProd : qte_itm,
        hdn_product_id : old_id,
        hdn_is_model : is_model,
        produit_id_new : new_id,
        type : type,
        type_page : (page !== undefined && page !== '' ) ? page : 'product',
        is_achat_express : is_wishlist || is_lookbook ? 1 : 0,
        is_achat_express_v2 : is_achat_express && !is_wishlist ? 1 : 0,
        cpt_prod : cpt_prod-1,
        isCategSuper : isCategSuper
    };

    if (is_wishlist) {
        data.wishlist = true;
    }

    $.ajax({
        type : 'post',
        url : path_relative_root + 'ajax_get_product_regroup_ref.php',
        data : data,
        success : function (res) {
            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error('parseJSON');
                return;
            }
            if (res.success == 'ok') {
                var datas = res.datas;

                if (data.type_page == 'product' || data.type_page == 'assoc') {
                    var $html_main = $(stripCombo(datas.html_main));
                    var main_wrapper = $('.product_main_wrapper', $html_main);
                    var feats_wrapper = $('.product_feats_wrapper', $html_main);
                    var zoom_box_wrapper = $('#zoom_box_wrapper', $html_main);
                    var assoc_products = $('.product_assoc_container', $html_main);

                    if (data.is_achat_express_v2) {
                        // Photo et information
                        var itm_rr_id_rand = rand !== '' ? itm_rr_id.replace('_','-') + '-' + rand : itm_rr_id.replace('_','-');
                        var itm_id = old_id + (itm_rr_id !== '' ? itm_rr_id_rand : '');
                        var old_item_instance = $('#itm-' + old_id + itm_rr_id_rand);
                        $('#itm-' + itm_id).replaceWith($html_main);
                        var new_itm_id = new_id + (itm_rr_id !== '' ? itm_rr_id_rand : '');
                        var margin_right = $('#itm-' + new_itm_id ).next().css('margin-right');

                        if ($(".homepage").length>0 || $(".satellite_store_details").length>0) {
                            save_description = $('#itm-' + new_itm_id + ' .wrapper_description').html();
                            $('#itm-' + new_itm_id + ' .wrapper_description').html('<div class="name">'+save_description+'</div>');
                            save_item_title = $('#itm-' + new_itm_id + ' .item_title').html();
                            $('#itm-' + new_itm_id + ' .item_title').html('<span>'+save_item_title+'</span>')
                            $('#itm-' + new_itm_id + ' .wrapper_description').prepend($('#itm-' + new_itm_id + ' .item_price'));
                            
                            if ($(".homepage").length>0 ) {
                                var model;

                                if($('#itm-' + new_itm_id ).next('.item.swiper-slide').length){
                                    model = $('#itm-' + new_itm_id ).next('.item.swiper-slide');
                                } else {
                                    model = $('#itm-' + new_itm_id ).prev('.item.swiper-slide');
                                }
                                var home_margin_right = $(model).css('margin-right'),
                                    width = $(model).css('width');

                                $('#itm-' + new_itm_id ).css({
                                    marginRight: home_margin_right,
                                    width: width
                                });
                            }

                            if($(".satellite_store_details").length>0) {
                                $('#itm-' + new_itm_id ).addClass('swiper-slide');
                                $('#itm-' + new_itm_id ).css('margin-right',margin_right);
                            }
                        } else if ($(".product_page").length>0 || $(".rayon_lookbook").length>0) {
                            if ($(".product_page").length>0) {
                                
                                // Add previous item's classes and styling the to new item (only for product_assoc -> Fiche Produit)
                                $('#productAssociationSwiper #itm-' + new_itm_id ).addClass($(old_item_instance).attr("class"));
                                $('#productAssociationSwiper #itm-' + new_itm_id).attr("style", $(old_item_instance).attr("style"));

                                // Add previous item's classes and styling the to new item (only for visited_articles -> Fiche Produit)
                                let dataProdId = new_itm_id.slice(0, -2);
                                
                                if ($('#productVisitedSwiper [data-prod=' + dataProdId + ']').length) {
                                    $('#productVisitedSwiper [data-prod=' + dataProdId + ']').addClass($(old_item_instance).attr("class"));
                                    $('#productVisitedSwiper [data-prod=' + dataProdId + ']').attr("style", $(old_item_instance).attr("style"));
                                } else {
                                    dataProdId = dataProdId.split('-')[0];

                                    $('#productVisitedSwiper [data-prod=' + dataProdId + ']').addClass($(old_item_instance).attr("class"));
                                    $('#productVisitedSwiper [data-prod=' + dataProdId + ']').attr("style", $(old_item_instance).attr("style"));
                                }

                            } else if($(".rayon_lookbook").length>0) {
                                $('#itm-' + new_itm_id + ' .imgWrapper').addClass('imgWrapperinlook');
                            }
                        }
                        $('input.color_' + new_id + itm_rr_id_rand).prop('checked', false);

                        $('input#color_' + new_id + '_' + data.couleurProd + itm_rr_id_rand).prop('checked', true);
                    } else if (is_wishlist) {
                        // Photo et information
                        $('.product_main_wrapper', '#lightbox_achat_express').html(main_wrapper.html());
                        // Description compo, livraison, guide tailles
                        $('.product_feats_wrapper', '#lightbox_achat_express').html(feats_wrapper.html());
                    } else {
                        var $html_middle = $(stripCombo(datas.html_middle));

                        var color = $("#ligne_couleur").html();

                        var taille = $("#ligne_pointure").html(); 

                        var contents = $('<div />').html($html_main); 

                        // Photo et information
                        $('.product_main_wrapper', '#site_global_wrap').html(main_wrapper.html());
                        // Description compo, livraison, guide tailles
                        $('.product_feats_wrapper', '#site_global_wrap').html(feats_wrapper.html());
                        //zoom box
                        $('#zoom_box_wrapper').html(zoom_box_wrapper.html());
                        //assoc products
                        $('.product_assoc_container').html(assoc_products.html());

                        var video = $('.wrapper_video', contents);

                        $("#ligne_couleur").html(color);
                        $('input.color').prop('checked', false);
                        $('input#color_' + data.couleurProd).prop('checked', true);
                        /*$("#ligne_pointure").html(taille);
                        $('input.size').prop('checked', false);
                        $('input#size_'+data.tailleProd).prop('checked', true);*/

                        // On scroll en haut de la page dans le cas d'un regroupement
                        // $('html, body').animate({
                        //     scrollTop: 0
                        // }, 500);
                        if ($('#productAssociationSwiper .swiper-slide').length > 1) {
                            /* Product - association gondole  */
                            var swiper = new Swiper('#productAssociationSwiper', {
                                // Screen's width < 1025px
                                slidesPerView: 5,
                                spaceBetween: 5,
                                navigation: {
                                    nextEl: '.product-assoc-button-prev',
                                    prevEl: '.product-assoc-button-next',
                                },
                                allowTouchMove: false,
                            });
                        }

                    }

                    if ($("#buyNowSwiper .swiper-slide").length > 1) {
                        setTimeout(() => {
                            var buyNowSwiper = new Swiper('#buyNowSwiper', {
                                slidesPerView: 1,
                            });
                        }, 100);
                    }

                    if ($('#productAssociationSwiper').length) {
                        $("#productAssociationSwiper .productVisualMulti").each(function() {
                            new Swiper(this, {
                                slidesPerView: 1,
                                navigation: {
                                    nextEl: '.rollover_rayonsNext',
                                    prevEl: '.rollover_rayonsPrev',
                                },
                            });
                        });
                    }
                    
                } else if (type_page == 'look') {

                    var $html_main = $(stripCombo(datas.html_main));

                    var $html_middle = $(stripCombo(datas.html_middle));

                    var color = $("#ligne_couleur").html();

                    var main_wrapper = $('.product_main_wrapper', $html_main);

                    var feats_wrapper = $('.product_feats_wrapper', $html_main);

                    var contents = $('<div />').html($html_main);


                    // Photo et information
                    $('.product_main_wrapper', '#lightbox_achat_express').html(main_wrapper.html());
                    // Description compo, livraison, guide tailles
                    $('.product_feats_wrapper', '#lightbox_achat_express').html(feats_wrapper.html());


                    $('#ref_look_' + produit_principal).html($('#ref_look_' + produit_principal, $html_main).html());
                    $('#look_add_lightbox_' + produit_principal).html($('#look_add_lightbox_' + produit_principal, $html_main).html());

                    calculTotal('slide_look_detail');
                    $('#itm_thumb_list').empty();
                    var thumbnails = loadPrevisu();
                    parseThumbList(thumbnails);

                    var $id_current = id_prod.substring(id_prod.lastIndexOf("_") + 1);
                    var i = array_look.indexOf(parseInt($id_current));

                    if (i != -1) {

                        array_look.splice(i, 1);
                    }

                    // Une fois les informations chargés on vas mettre à jour le select des quantités avec le nouveau produit choisie
                    showQteListeGamme('liste_qte_' + datas.product.id,  datas.product.id, path_relative_root);

                }

                json_colisages = res.stock;
                // On definie les tailles qui ont encore du stock

                if (!data.is_achat_express_v2) {
                    setAvailableSizes.call($('[name="itm_color"]:checked', $(form)));
                }

                if(!$('.homepage'.length)){
                    setTimeout(() => {
                        const swipers = document.querySelectorAll('.productSwiper');
                        swipers.forEach((container, index) => {
                            if ($(container).find('.productSwiper').length == 0 && $(container).find('.swiper-wrapper').length && $(container).find('.swiper-slide').length > 1) {
                                var productSwiper = new Swiper(".productSwiper", {
                                    slidesPerView: 1,
                                    navigation: {
                                        nextEl: '.rollover_rayonsNext',
                                        prevEl: '.rollover_rayonsPrev',
                                    },
                                    preloadImages: false,
                                    lazy: true,
                                    loadPrevNext: true,
                                    loadOnTransitionStart: true
                                });
                            }
                        });
                    }, 500);
                }

                if (wShop.$refs.wAlertestockForm) {
                    wShop.$refs.wAlertestockForm.reloadBloc += 1;
                }

            } else{
                $('#btn_error').fadeIn();
            }
            
            // Active la fonction swiperHomeModules si elle est presente
            if ( typeof swiperHomeModules === "function" ) {
                swiperHomeModules();
            }

            removePromoTxt();
        },

        complete: function() {
            var heads      = $("[data-tab-head]");
            var containers = $("[data-tab-container]");

            if (window.YT_Player !== undefined) {

                window.YT_Player.destroy();

                window.YT_Player = undefined;
                window.YT_vid_id = undefined;
            }

            var activateTab = function(event) {
                event.preventDefault();

                var element        = $(this);
                var tab_number     = element.attr("data-tab");
                var head_container = element.parent();
                var container_name, container, scrollpane;

                head_container.addClass("current").siblings().removeClass("current");
                container_name = head_container.parent().attr("data-tab-head");
                container      = containers.filter("[data-tab-container='" + container_name + "']");
                scrollpane     = container.find("[data-tab]").hide().filter("[data-tab='" + tab_number + "']").show().find('.scroll-pane');

                if (scrollpane.length > 0) {
                    scrollpane.jScrollPane();
                }
            };

            setTimeout(function () {
                if($(".productThumbs_test").length > 0) {
                    var productThumbs = new Swiper('.productThumbs_test', {
                        slidesPerView: 2,
                        slidesPerColumn: 2,
                        slidesPerGroup: 2,
                        navigation: {
                            nextEl: '.swiper-button-nextFP',
                            prevEl: '.swiper-button-prevFP',
                        },
                    });
                }
                /* Fin reinitialisation sliders visus */

                var more_vis = $('.more_vis');
                var grabber;

                more_vis.each(function () {

                    var jqzoom = $(this).next('#jqzoom');

                    if (jqzoom.length > 0) {

                        $(this).height(jqzoom.height());

                        if (document.getElementById('visual_scroller')) {

                            setTimeout(function () {

                                grabber = new Grabber(document.getElementById('visual_scroller'), true, false);
                            }, 250);
                        }
                    }

                    var zoom_box_wrapper = $(this).next('#zoom_box_wrapper');

                    if (zoom_box_wrapper.length > 0) {

                        $(this).height(zoom_box_wrapper.height());

                        if (document.getElementById('thumbs_scroller')) {

                            setTimeout(function () {

                                grabber = new Grabber(document.getElementById('thumbs_scroller'), true, false);
                            }, 250);
                        }
                    }

                });

                if (!data.is_achat_express_v2) {
                    $("#product_addcart .bloc_add_alert_form").hide(); // Resetting alert stock field
                }

                var productImgsWrapper = $(".productImgsWrapper");
                var productDescrWrapper = $(".productDescrWrapper");

                $(document).on("scroll", function () {

                    var scrollTop = $(document).scrollTop();
                    var newTop = (scrollTop - (productImgsWrapper.innerHeight() - productDescrWrapper.innerHeight()));

                    if(scrollTop >= productImgsWrapper.innerHeight() - productDescrWrapper.innerHeight()) {

                        productDescrWrapper.css("top", "-" + newTop + "px");
                    } else {

                        productDescrWrapper.css("top", "auto");
                    }
                });

                $(window).on('resize', function () {

                    more_vis.each(function () {

                        var jqzoom = $(this).next('#jqzoom');

                        if (jqzoom.length > 0) {

                            $(this).height(jqzoom.height());
                        }

                        var jqzoom = $(this).next('#zoom_box_wrapper');

                        if (jqzoom.length > 0) {

                            $(this).height(zoom_box_wrapper.height());
                        }
                        
                    });
                });

                setTimeout(function () {
                    checkSubmitState();
                    eventButton();
                }, 500);
            }, 250);

            heads.each(function(){
                var head = $(this);
                var children = head.children();
                children.removeClass("current").filter(":first").addClass("current");
                children.find('[data-tab]').click(activateTab);
            });

            $("[data-tab-container]").children('div').eq(Math.abs(1 - $('.current', "[data-tab-head]").index())).css('display', 'none');

            var assocs_elm = $('#assocs_slider', '#site_global_wrap').get(0);
            var ranges_elm = $('#ranges_slider', '#site_global_wrap').get(0);
            
            getAvailableQuantity.call($('form.product_info', '#site_global_wrap').get(0), data.produit_id_new, data.qteProd,'', data.couleurProd, itm_rr_id, is_wishlist);

            checkProductInWishlist(data.produit_id_new);
            if (!$(".w-alertestock-form").length && $('#keep_alive_stock').length) {
                new Vue({components:{wAlertestockForm: wAlertestockForm}}).$mount('#keep_alive_stock');
            }
            if (data.is_achat_express_v2) {
                if ($(".productVisualMulti").length > 0 && !$(".homepage").length) {
                    $(".productVisualMulti").each(function() {
                        // Slides perview
                        var spv = 1;

                        // If recetly visited swiper we change slide to "auto" because of a bug with the width when swiper is initiated
                        if ($(this).parents("#productVisitedSwiper").length > 0) {
                            spv = "auto";
                        }

                        $(this).find(".productSwiper").each(function() {
                            new Swiper(this, {
                                slidesPerView: spv,
                                navigation: {
                                    nextEl: '.rollover_rayonsNext',
                                    prevEl: '.rollover_rayonsPrev',
                                },
                            });
                        });
                    });
                }

                if ($('#productAssociationSwiper').length) {
                    $("#productAssociationSwiper .productVisualMulti").each(function() {
                        new Swiper(this, {
                            slidesPerView: 1,
                            navigation: {
                                nextEl: '.rollover_rayonsNext',
                                prevEl: '.rollover_rayonsPrev',
                            },
                        });
                    });
                }
            } else {
                $("#addToWishlistButton").on({
                    click: function (evt) {
                        // SI le bouton d'ajout est ok alors on lance l'ajout à la wishlist
                        if (!$(this).hasClass("existToWishlistButton")) {
                            addToWishlist(evt);
                        }
                    }
                });
            }

            removePromoTxt(); 

            // Rajouter pour que isCategSuper rest 0 pour la recherche.
            var searchIsCategSuper = $('.dynasearchwrapper .productSwiper.productVisualMulti form input[name="isCategSuper"]');
            if(searchIsCategSuper.val() != 0) {
                searchIsCategSuper.val("0");
            }

            if ($(".dynasearchwrapper .productVisualMulti").length > 0) {
                $(".dynasearchwrapper .productVisualMulti").each(function() {
                    $(this).find(".productSwiper").each(function() {
                        new Swiper(this, {
                            slidesPerView: 1,
                            navigation: {
                                nextEl: '.rollover_rayonsNext',
                                prevEl: '.rollover_rayonsPrev',
                            },
                        });
                    });
                });
            }
        }
    });
}

// Alters item quantity in cart
function changeQte(type) {
    var types = ['plus', 'less'],
        item, loader, qtty, ajax_url;
    if (types.includes(type)) {
        item = this.getValue('item_id', parseFloat);
        loader = document.getElementById('qte_loader_' + item);
        qtty = this.getValue('selected_qte', parseFloat);
        var qtty_select_wrap = $(this).find('fieldset.selector');
        var loader = $(this).find('img.load_qte');
        
        qtty_select_wrap.css("display", "none" );
        loader.css("display", "block");
        switch (type) {
            case 'plus':
                qtty += 1;
                break;
            case 'less':
                qtty -= 1;
                break;
        }
        $.ajax({
            url: path_relative_root + create_link('order_basket'),
            type: 'post',
            data: {
                action: 'del',
                panierId: item,
                cad: 'non',
                pointcad: 0,
                qteProd: qtty,
                change_qte: true
            },
            cache: false,
            success: function (res) {
                try {
                    res = JSON.parse(res);
                } catch (e) {
                    console.error('JSON ERROR: could not parse data');
                    return false;
                }
                res = $('<div />').html(res.datas);
                document.getElementById('main_cart_wrapper').innerHTML = $('#main_cart_wrapper', res).html();
                wShop.$refs.wCouponForm.reloadBloc += 1;
                //update top cart

                 var response = ajax_file("ajax_show_basket.php");

                cart = translate('mon_panier');

                $('.cart_main_title.basket').html(cart + ' ' + '<span class="nb_products">(' + total + ' ' + text + ')</span>')
                $('#texte_question_6').removeClass('cache');
                $('#intitule_question_6').addClass('cache');
                document.getElementById('cart_top').innerHTML = response;

                var total = $('#cart_top .cart').attr('data-cart'),
                    text;

                if(total <= 1){
                    text = translate('one_product');
                } else {
                    text = translate('2_product');
                };

                $('.cart_main_title.basket').html(cart +' '+ '<span class="nb_products">(' + total + ' ' + text + ')</span>');
            }
        });
    }
}

// // Called upon color change in product page
function onItemColorChange(form, is_regroup, itm_rr_id) {

    $(this).closest('.productVisualMulti').css({
        opacity: '0'
    });

    var control, prod_id;
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var typePage = $(form).find('input[name="isAssocProduct"]').val() == 1 ? 'assoc' : '';
    var is_wishlist = $('#wishlist').length > 0 && typePage !== 'assoc';
    var is_achat_express_v2 = ($('#is_achat_express_v2').val() == 1 && !is_wishlist);

    if (this.checked) {

        control = form.elements.namedItem(form.getValue('idCouleur'));
        control.value = this.value.trim();
        prod_id = form.getValue('itm_id_' + control.value + itm_rr_id).trim();
        rand = itm_rr_id !== '' ? itm_rr_id.split('_')[2] : '';
        itm_rr_id = itm_rr_id !== '' ? '_' + itm_rr_id.split('_')[1] : '';
        if (is_regroup) {
            loadProduct.call(form, prod_id, 'couleur' , typePage, itm_rr_id, rand);
        } else {
            // On regarde si le produit est dejà dans la wishlist
            if (!is_achat_express_v2) {
                button_add_to_wishlist_initial_click_event = $("#addToWishlistButton").attr('onclick');
                checkProductInWishlist();
            }

            var img = $(this).data('pic');
            changeVisu('img_large', img);

            // On definie les tailles qui ont encore du stock
            setAvailableSizes.call(this);
        }
    }
}

// // Called upon size change in product page
function onItemSizeChange(form, event, itm_rr_id) {

    var control;
    var is_wishlist = $('#wishlist').length > 0;
    if ($('#tunnel_right_col').length > 0) {
        is_wishlist = false;
    }
    var is_achat_express_v2 = ($('#is_achat_express_v2').val() == 1 && !is_wishlist);

    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';

    if (this.checked) {

        if ($('#sizeerror').is(':visible')) {
            $('#sizeerror').slideUp();
        }

        var id= form.getValue('produit_id');
        var elem = is_achat_express_v2 ? '_' + id + itm_rr_id : '';

        control = form.elements.namedItem('tailleProd');
        control.value = this.value.trim();

        // En fonction de la couleur on regarde si le produit est disponible
        getAvailableQuantity($('#produit_principal' + elem).val(), $('#qteProd' + elem).val(), 'product', event, itm_rr_id, is_wishlist, this);
        // On regarde si le produti est dejà dans la wishlist
        if (!is_achat_express_v2) {
            checkProductInWishlist(id);
        } else {
            $("#bloc_add_color" + elem).parent().show().css('opacity', '1')
        }
    }
}

/**
 * @param product_id : l'id du produit
 * @param regroup_ref_id : l'id du regroupement (si exist)
 * use in : app/views/_components/product/achat_express.php
 */
function achatExpressV2(product_id, regroup_ref_id) {
    var form = this.form;
    setTimeout(function() {

        waitingAddToBasket(event, form, '', regroup_ref_id);

    }, 200);
    
    var elem = '_' + product_id + regroup_ref_id;
    setTimeout(() => {
        $("#bloc_add_color" + elem).parent().hide();
    }, 3000);
}

// // Refreshes available quantity
function getAvailableQuantity(id, qty, from, event, itm_rr_id, is_wishlist, currentElem) {
    let visitedArticles = (currentElem && currentElem.classList && currentElem.classList.contains("visited_articles_size")) ? "#gondole_derniers_articles_vus " : "";
    itm_rr_id = (itm_rr_id !== undefined) ? itm_rr_id : '';
    var is_achat_express = $('#is_achat_express_v2').val() == 1 && !is_wishlist;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';

    var form = (this instanceof HTMLFormElement) ? this : document.querySelector(visitedArticles + '#prod_info_' + id + itm_rr_id);
    var color_id = $(visitedArticles + '#couleurProd' + elem).val();
    var size_id = $(visitedArticles + '#tailleProd' + elem).val();

    var plnb;
    if (form != null) {
        plnb = form.getValue('produit_lot_nb');
    }

    // Si on a pas de taille ni de couleur déjà selectionné alors on ne fait pas de vérification
    if (color_id == '' || size_id == '') {
        return;
    }

    var data = {
        idprod: $(visitedArticles + '#produit_id' + elem).val(),
        idcouleur: color_id,
        idtaille: size_id,
        json: '1'
    };

    if (from == 'basket') {
        data.suff = form.getValue('produit_id');
    }

    if (plnb) {
        data.produit_lot_nb = plnb;
    }

    $.ajax({
            url: path_relative_root + create_link('ajax_liste_quantity'),
        type: 'get',
        async: false, // Should be async but would then need a loader so...
        dataType: 'json',
        data: data,
        success: function (res) {

            if (res.stock_tampon == 0) {

                /**
                 * Flag alert stock
                 * If enable, bloc_add_alert exist in DOM, hide add basket and display alert stock
                 * If disabled, bloc_add_alert don't exist in DOM, display disabled add basket
                 */
                if ($(visitedArticles + ".bloc_add_alert" + elem).length > 0) {
                    $(visitedArticles + ".bloc_add_alert" + elem).show();
                    if (!is_achat_express) {
                        $(visitedArticles + "#bloc_add_basket").hide();
                    }
                } else {
                    if (is_achat_express) {
                        $(visitedArticles + "#bloc_size_not_available" + elem).show();
                    } else {
                        $(visitedArticles + ".product_main_wrapper #size_is_not_available").show();
                        disableAddCart();
                    }
                }
                $(".eclat_product_nouveaute_fiche_produit").show();
                if (document.querySelector(visitedArticles + "#prod_info" + elem + " .rollover_left")) {
                    document.querySelector(visitedArticles + "#prod_info" + elem + " .rollover_left").style.display = "none";
                }
                $("#qteProd").val(0);
                $('#ligne_qte').append($('.restock_date'));
                $('.taunt_text').css('display', 'none');
            } else { // If product have stock
                if (!is_achat_express) {
                    $(visitedArticles + ".product_main_wrapper #size_is_not_available").hide();
                }
                if (res.stock_tampon > 0 && res.stock_tampon <= 3) {
                    $('.taunt_text').css('display', 'block');
                } else {
                    $('.taunt_text').css('display', 'none');
                }

                if(!is_achat_express) {
                    $(".eclat_product_nouveaute_fiche_produit").hide();
                    $(".bloc_add_alert").hide();
                    $("#bloc_add_basket").show();

                }
            };
            // Datalayer v2
            if (res.dataLayer_object != undefined) {
                pushIntoDatalayer(res.dataLayer_object);
            }
        }
    });
}

function closeSizeNotAvailable(elem) {
    let visitedArticles = (this && this.classList && this.classList.contains("visited_articles_alert_return")) ? "#gondole_derniers_articles_vus " : "";

    // Hide bloc bloc_size_not_available_
    $(visitedArticles + "#bloc_size_not_available_" + elem).hide();

    // Show bloc colors and size
    $(visitedArticles + "#prod_info_" + elem + " .rollover_left").show();
    $(visitedArticles + "#ligne_pointure_" + elem).show();
    $(visitedArticles + "#ligne_couleur_" + elem).show();
}

function newUpdatePanier_legacy(
    idProduit,
    i,
    paniervirtuelId,
    prixU,
    path_web,
    titreObjet,
    idElTaille,
    lastTaille,
    idElCouleur,
    lastCouleur
) {
    if (i != "") {
        i = "_" + i;

        prixU = $("#prixU" + i).val();
    }

    var couleurs = document.getElementById("couleurProd" + i);
    var tailles = document.getElementById("tailleProd" + i);
    var $alertbox = $("#alertbox1");
    var res = "";
    var html_err = "";
    var flagError = false;
    if ($("#lightbox_achat_express").length > 0) {
        from_range = true;
    } else {
        from_range = false;
    }

    html_err = '<div class="txt_alert"></div><div class="rosette"></div>';
    html_err +=
        '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';
    if ($alertbox.length == 0) {
        $alertbox = $('<div id="alertbox1">' + html_err + "</div>");
        $("body").append($alertbox.hide());
    }

    if (tailles && tailles.value == "") {
        res = ajax_file(path_web + "ajax_sprintf.php?arg1=js_error_size");
        flagError = true;
    } else if (couleurs && couleurs.value == "") {
        res = ajax_file(path_web + "ajax_sprintf.php?arg1=js_error_couleur");
        flagError = true;
    } else if (
        document.getElementById("qteProd" + i) &&
        document.getElementById("qteProd" + i).value == ""
    ) {
        res = ajax_file(path_web + "ajax_sprintf.php?arg1=js_error_quantity");
        flagError = true;
    }

    if (flagError) {
        $alertbox.find(".txt_alert").html(res);
        openMultiShad("alertbox1");
        return false;
    } else {
        var qte = 0;

        if ($("#qteProd" + i)) {
            qte = $("#qteProd" + i).val();
        }

        var idCouleur = 0;
        var idTaille = 0;

        if (couleurs && couleurs.value != "none") idCouleur = couleurs.value;
        if (tailles && tailles.value != "none") idTaille = tailles.value;

        var array_response = new Array();
        var response = ajax_file(
            path_web +
                "ajax_upd_panier.php?prixu=" +
                prixU +
                "&idprod=" +
                idProduit +
                "&idcouleur=" +
                idCouleur +
                "&idtaille=" +
                idTaille +
                "&qte=" +
                qte +
                "&panierId=" +
                paniervirtuelId
        );

        if (response) {
            array_response = response.split("//");

            var designation = array_response[0];

            if (titreObjet.match("^[0-9]")) {
                if (designation == 1) {
                    designation += " lot de";
                } else {
                    designation += " lots de";
                }
            }

            designation += " " + titreObjet;

            if (document.getElementById(idElTaille)) {
                if (document.getElementById(idElTaille).selectedIndex) {
                    //designation += ' - ' + document.getElementById(idElCouleur).text; // ne fonctionne pas
                    designation +=
                        " - Taille " +
                        document.getElementById(idElTaille).options[
                            document.getElementById(idElTaille).selectedIndex
                        ].text;
                } else if (lastTaille != "") {
                    designation += " - Taille " + lastTaille;
                }
            }

            if (document.getElementById(idElCouleur)) {
                if (document.getElementById(idElCouleur).selectedIndex) {
                    //designation += ' - ' + document.getElementById(idElCouleur).text; // ne fonctionne pas
                    designation +=
                        " - " +
                        document.getElementById(idElCouleur).options[
                            document.getElementById(idElCouleur).selectedIndex
                        ].text;
                } else if (lastCouleur != "") {
                    designation += " - " + lastCouleur;
                }
            }

            array_response[0] = designation;
        }

        return array_response;
    }
}

$(document).on("click", ".thumblnk", function(evt) {
    var id_pannel = this.getAttribute("data-pannel");
    var thumbnav = $(this).closest(".thumbnav");
    var thumbcnt = thumbnav.parent().next(".thumbcnt");

    evt.preventDefault();

    thumbnav
        .find(".thumblnk")
        .not(".thumblnk[data-pannel=" + id_pannel + "]")
        .removeClass("current");
    thumbcnt
        .find(".thumbslide")
        .not(".thumbslide[data-pannel=" + id_pannel + "]")
        .removeClass("current");

    thumbnav
        .find(".thumblnk[data-pannel=" + id_pannel + "]")
        .addClass("current");
    thumbcnt
        .find(".thumbslide[data-pannel=" + id_pannel + "]")
        .addClass("current");
});

function closeAlerteStock(elem) {

    // Hide bloc alert stock
    $('.bloc_add_alert_' + elem).hide();
    $("#prod_info_" + elem + " .rollover_left").show();

    // Show bloc colors and size
    $('#ligne_pointure_' + elem).show();
    $('#ligne_couleur_' + elem).show();
    //$("#prod_info_" + elem + " .bloc_add_color").show();

    // Reset mail input
    $('.bloc_add_alert_' + elem + ' .alert_stock').show();
    $('.bloc_add_alert_' + elem + ' .bloc_add_alert_form').show();
    $('.bloc_add_alert_' + elem + ' .bloc_add_alert_confirmation').hide();
}